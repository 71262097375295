//
// password.js
//

const toggles = document.querySelectorAll('[data-toggle="password"]');

toggles.forEach((toggle) => {
  toggle.addEventListener("click", function (e) {
    e.preventDefault();

    const target = document.querySelector(toggle.getAttribute("href"));
    const type = target.type === "password" ? "text" : "password";

    target.type = type;
  });
});
